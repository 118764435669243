import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Languages = _resolveComponent("Languages")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.user.user)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.user.user.displayName) + "!! ", 1)
        ], 64))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    _createVNode(_component_Languages, { class: "mt-4" })
  ]))
}