import languages from "./languages";

const lang = {
  message: {
    hello: "hello world",
  },
  languages,
};

export default lang;
