
import { defineComponent } from "vue";
//import HelloWorld from "@/components/HelloWorld.vue";

export default defineComponent({
  name: "HomePage",
  components: {
//    HelloWorld,
  },
});
