export const firebaseConfig = {
  apiKey: "AIzaSyAd-pX35BsCvIBDXstf_LmQaz82PNFGcMI",
  authDomain: "fir-vue-startup-kit.firebaseapp.com",
  databaseURL: "https://fir-vue-startup-kit.firebaseio.com",
  projectId: "fir-vue-startup-kit",
  storageBucket: "fir-vue-startup-kit.appspot.com",
  messagingSenderId: "225404938985",
  appId: "1:225404938985:web:a2cea9fab1722fce",
  messageKey:
    "BGwIKr3yZdXmg4Pl-BpIQX_ETtHYl0RqvAJFoc-hYKFyaoU3wZXarqto5DSq1cemFuARMjFvJ02B83T5ga42MKo",
};
