import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isSignedIn)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_router_link, {
              to: _ctx.localizedUrl('/')
            }, {
              default: _withCtx(() => [
                _createTextVNode("Home")
              ]),
              _: 1
            }, 8, ["to"]),
            _createTextVNode(" | "),
            _createVNode(_component_router_link, {
              to: _ctx.localizedUrl('/about')
            }, {
              default: _withCtx(() => [
                _createTextVNode("About")
              ]),
              _: 1
            }, 8, ["to"])
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_router_link, {
              to: _ctx.localizedUrl('/')
            }, {
              default: _withCtx(() => [
                _createTextVNode("Home")
              ]),
              _: 1
            }, 8, ["to"]),
            _createTextVNode(" | "),
            _createVNode(_component_router_link, {
              to: _ctx.localizedUrl('/about')
            }, {
              default: _withCtx(() => [
                _createTextVNode("About")
              ]),
              _: 1
            }, 8, ["to"]),
            _createTextVNode(" | "),
            _createVNode(_component_router_link, {
              to: _ctx.localizedUrl('/account')
            }, {
              default: _withCtx(() => [
                _createTextVNode("Signin")
              ]),
              _: 1
            }, 8, ["to"])
          ], 64))
    ]),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }))
  ], 64))
}