
import { defineComponent, computed } from "vue";
import { useUser, useIsSignedIn } from "@/utils/utils";

export default defineComponent({
  setup() {
    const user = useUser();
    const isSignedIn = useIsSignedIn();

    return {
      user,
      isSignedIn,
    };
  },
});
